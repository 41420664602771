.post-job-form-steps-container {
    width: 100%;
    margin-left: -10%;
}

.post-job-form-steps .ant-steps-item-content {
    display: none;
}

@media (max-width: 500px) {
    .post-job-form-steps-container {
        margin-left: -15%;
    }
    .post-job-form-steps {
        width: 90vw; 
    }
}

@media (max-width: 320px) {
    /* The step is not centered when under 320px, apply fix */
    .post-job-form-steps-container {
        margin-left: -35%;
    }
}
