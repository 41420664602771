.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.jobPostcode,
.jobTitle {
  flex: 3;
  margin-right: 10px !important;
  border-radius: 10px !important;
  height: 60px !important;
  background-color: white !important;
  font-family: 'Sans-500';
  font-size: 20px !important;
  color: #000 !important;
}

.jobPostcode {
  flex: 3 1;
}
.postJobBtn {
  flex: 1;
  height: 60px !important;
  border-radius: 10px !important;
  font-size: 20px !important;
  font-family: "Sans-700";
  background-color: #3d77ff !important;
}
.form-spacer {
  flex-basis: 100%;
  height: 0;
  display: none;
}

@media (max-width: 479px) {
  .form-spacer {
    display: block;
  }
}
@media (max-width: 500px) {
  .jobPostcode, 
  .jobTitle {
    font-size: 14px !important;
    margin-right: 5px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .jobTitle {
    flex:14;
  }
  .jobPostcode{
    flex:3;
  }
  .postJobBtn {
    padding-left:5px !important;
    padding-right:5px !important;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-modal-content {
  border-radius: 20px;
  padding: 0;
}

.ant-upload-list-picture-card-container {
  width: 60px;
  height: 60px;
  /* margin: 0 10px 10px 0; */
}
.ant-upload-list-picture-card .ant-upload-list-item {
  width: 60px;
  height: 60px;
  padding: 0;
  border-width: 0;
}
.ant-upload.ant-upload-select-picture-card {
  width: 60px;
  height: 60px;
  padding: 0;
}
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  font-size: 0;
}
/* .ant-upload-list-item .ant-upload-list-item-done {
  width: 60px;
  height: 60px;
}
.ant-upload-list-item-list-type-picture-card {
  width: 60px;
  height: 60px;
} */
/* mobile responsive */
