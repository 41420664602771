.upload-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

@media (max-width: 500px) {
  /* Make the style responsive */
  .upload-section {
    flex-direction: column;
  }
}
